import { IStoredShoppingList } from "@ng-mw/platform-interfaces"
import { LocalStorage, NativeEventBridge } from "@ng-mw/shared-react-components"
import { orderActions } from "@ng-mw/shared-react-components/orders"
import { Dispatch } from "redux"
import MenyLocalStorage from "@app/MenyLocalStorage"
import MenyNativeEvent from "@app/events/MenyNativeEvent"
import * as nativeActions from "@app/store/native/nativeActions"
import { RecipeItem } from "@app/types"
import { UserSeenTrumfIdModalKey } from "@modules/onboarding/pages/FirstTrumfIdModal"
import { CompareGroupPopupData } from "@modules/insight/diet/types/CompareGroupPopupData"
import * as appActions from "./appCreator"
import { ClimateComparisonPopupData, FeedbackPopupPayload, RegistrationPopupPayload } from "./appCreator"
import { GamePopupPayload } from "@modules/seasonal/types/GamePopupProps"
import { AppNewsData } from "@modules/onboarding/pages/NewsPopup"

export const scrollPositionChange = (top: boolean, bottom: boolean, goingDown: boolean) => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.scrollPositionChange(top, bottom, goingDown))
    }
}

export const popupScrollPositionChange = (top: boolean, goingDown: boolean) => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.popupScrollPositionChange(top, goingDown))
    }
}

export const showCategoryFilterPopup = (productViewId?: string) => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showCategoryFilterPopup(productViewId))
    }
}

export const showCouponInfoPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showCouponInfoPopup())
    }
}

export const hideCouponInfoPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideCouponInfoPopup())
    }
}

export const hideCategoryFilterPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideCategoryFilterPopup())
    }
}

export const showProductPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showProductPopup())
    }
}

export const hideProductPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideProductPopup())
    }
}

export const showBarcodePopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showBarcodePopup())
        NativeEventBridge.broadcastNative(MenyNativeEvent.ScanBarcode)
    }
}

export const hideBarcodePopup = () => {
    return (dispatch: Dispatch) => {
        NativeEventBridge.broadcastNative(MenyNativeEvent.ScanBarcodeDone)
        dispatch(appActions.hideBarcodePopup())
    }
}
export const barcodeFound = (productEan: string) => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.barcodeFound(productEan))
    }
}

export const showAppOnboardingPoster = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showAppOnboardingPoster())
    }
}

export const hideAppOnboardingPoster = () => {
    LocalStorage.set(MenyLocalStorage.UserSeenNewOnboarding, true)

    return (dispatch: Dispatch) => {
        dispatch(appActions.hideAppOnboardingPoster())
    }
}

export const showRegistrationPopup = (data?: RegistrationPopupPayload) => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showRegistrationPopup(data))
    }
}

export const hideRegistrationPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideRegistrationPopup())
    }
}

export const showHandoverPickerPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showHandoverPickerPopup())
    }
}

export const hideHandoverPickerPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideHandoverPickerPopup())
    }
}

export const showCheckoutPaymentPopup = (paymentUrl: string) => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showCheckoutPaymentPopup(paymentUrl))
    }
}

export const hideCheckoutPaymentPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(orderActions.cancelPaymentTerminal())
        dispatch(appActions.hideCheckoutPaymentPopup())
    }
}

export const showInsightAboutPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showInsightAboutPopup())
    }
}

export const hideInsightAboutPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideInsightAboutPopup())
    }
}

export const hideLoyaltyClubInfoPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideLoyaltyClubInfoPopup())
    }
}

export const showLoyaltyClubInfoPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showLoyaltyClubInfoPopup())
    }
}

export const showFeedbackPopup = (data: FeedbackPopupPayload) => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showFeedbackPopup(data))
    }
}

export const hideFeedbackPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideFeedbackPopup())
    }
}

export const showInsightKeyFiguresPopup = (data?: CompareGroupPopupData) => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showInsightKeyFiguresPopup(data))
    }
}

export const hideInsightKeyFiguresPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideInsightKeyFiguresPopup())
    }
}

export const setSafeArea = nativeActions.setSafeArea
export const setNetworkStatus = nativeActions.setNetworkStatus

// FROM TRUMF

export const showDietEnergyPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showDietEnergyPopup())
    }
}

export const hideDietEnergyPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideDietEnergyPopup())
    }
}

export const showDietGroupPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showDietGroupPopup())
    }
}

export const setDietGroupPopupTabIndex = (tabIndex: number) => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.setDietGroupPopupTabIndex(tabIndex))
    }
}

export const hideDietGroupPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideDietGroupPopup())
    }
}

export const showDietPropertyPopup = (navigationHeader?: string) => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showDietPropertyPopup(navigationHeader))
    }
}

export const hideDietPropertyPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideDietPropertyPopup())
    }
}

export const showInsightOnboardingPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showInsightOnboardingPopup())
    }
}

export const hideInsightOnboardingPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideInsightOnboardingPopup())
    }
}

export const showClimateNumbersPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showClimateNumbersPopup())
    }
}

export const hideClimateNumbersPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideClimateNumbersPopup())
    }
}

export const showClimateComparisonPopup = (data: ClimateComparisonPopupData) => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showClimateComparisonPopup(data))
    }
}

export const hideClimateComparisonPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideClimateComparisonPopup())
    }
}

export const showClimateInformationPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showClimateInformationPopup())
    }
}

export const hideClimateInformationPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideClimateInformationPopup())
    }
}

export const showClimateProductGroupsInfoPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showClimateProductGroupsInfoPopup())
    }
}

export const hideClimateProductGroupsInfoPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideClimateProductGroupsInfoPopup())
    }
}

export const showPurchaseDetailsPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showPurchaseDetailsPopup())
    }
}

export const hidePurchaseDetailsPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hidePurchaseDetailsPopup())
    }
}

export const showPurchaseComplaintPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showPurchaseComplaintPopup())
    }
}

export const hidePurchaseComplaintPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hidePurchaseComplaintPopup())
    }
}

export const showQrPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showQrPopup())
    }
}

export const hideQrPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideQrPopup())
    }
}

export const showAgreementDeactivationPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showAgreementDeactivationPopup())
    }
}

export const hideAgreementDeactivationPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideAgreementDeactivationPopup())
    }
}

export const showAppUpgradePopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showAppUpgradePopup())
    }
}

export const hideAppUpgradePopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideAppUpgradePopup())
    }
}

export const showForcedUpgradePopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showForcedUpgradePopup())
    }
}
export const hideForcedUpgradePopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideForcedUpgradePopup())
    }
}

export const showOsUpgradePopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showOsUpgradePopup())
    }
}
export const hideOsUpgradePopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideOsUpgradePopup())
    }
}

export const showNewsPopup = (data: AppNewsData) => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showNewsPopup(data))
    }
}
export const hideNewsPopup = (localStorageKey: string) => {
    LocalStorage.set(localStorageKey, true)
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideNewsPopup())
    }
}

export const showActivateLoyaltyClubPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showActivateLoyaltyClubPopup())
    }
}
export const hideActivateLoyaltyClubPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideActivateLoyaltyClubPopup())
    }
}

export function showTrumfIdModal() {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showTrumfIdModal())
    }
}
export function hideTrumfIdModal() {
    LocalStorage.set(UserSeenTrumfIdModalKey, true)
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideTrumfIdModal())
    }
}

export const showShoppingListRemovePopup = (shoppingList: IStoredShoppingList) => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showShoppingListRemovePopup(shoppingList))
    }
}

export const hideShoppingListRemovePopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideShoppingListRemovePopup())
    }
}

export const showShoppingListChangeNamePopup = (shoppingList: IStoredShoppingList) => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showShoppingListChangeNamePopup(shoppingList))
    }
}

export const hideShoppingListChangeNamePopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideShoppingListChangeNamePopup())
    }
}

export const showShoppingListCreatePopup = (shoppingListId?: string) => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showShoppingListCreatePopup(shoppingListId))
    }
}

export const hideShoppingListCreatePopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideShoppingListCreatePopup())
    }
}

export const showRecipePopup = (recipeId: string) => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showRecipePopup(recipeId))
    }
}

export const hideRecipePopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideRecipePopup())
    }
}

export const showRecipeRatingPopup = (recipeItem: RecipeItem) => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showRecipeRatingPopup(recipeItem))
    }
}

export const hideRecipeRatingPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideRecipeRatingPopup())
    }
}

export const showLoginPopup = (data: appActions.LoginOutPopupPayload) => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showLoginPopup(data))
    }
}

export const hideLoginPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideLoginPopup())
    }
}

export const showLogOutPopup = (data?: appActions.LoginOutPopupPayload) => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showLogOutPopup(data))
    }
}

export const hideLogOutPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideLogOutPopup())
    }
}

export const showWelcomePopup = (data?: any) => {
    return (dispatch: Dispatch) => {
        LocalStorage.set(MenyLocalStorage.UserSeenLoyaltyClubWelcomePopup, true)
        dispatch(appActions.showWelcomePopup(data))
    }
}

export const hideWelcomePopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideWelcomePopup())
    }
}

export const showPushConsentsPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showPushConsentsPopup())
    }
}

export const hidePushConsentsPopup = () => {
    return (dispatch: Dispatch) => {
        LocalStorage.set(MenyLocalStorage.UserSeenPushPopup, true)
        dispatch(appActions.hidePushConsentsPopup())
    }
}

export const showGamePopup = (data: GamePopupPayload) => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showGamePopup(data))
    }
}
export const hideGamePopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hideGamePopup())
    }
}

export const showPersonalCampaignsPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.showPersonalCampaignsPopup())
    }
}
export const hidePersonalCampaignsPopup = () => {
    return (dispatch: Dispatch) => {
        dispatch(appActions.hidePersonalCampaignsPopup())
    }
}
