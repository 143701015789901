import * as campaignsActions from "./creators/campaigns"
import { performRESTRequest } from "@ng-mw/framework-core"
import patchStore from "../parsers/patch-store"
import { Logger, Cacher } from "@ng-mw/shared-react-components"
import { Dispatch } from "redux"
import { CampaignType, CampaignTypeEnum } from "@app/types"
import orderBy from "lodash/orderBy"

const getTags = (campaignType: CampaignTypeEnum) => {
    switch (campaignType) {
        case CampaignType.Offer:
            return "tilbud,banner"
        case CampaignType.Bargain:
            return "knallkjøp,banner"
        case CampaignType.Home:
            return "app_hjem"
        case CampaignType.LoyaltyClub:
            return CampaignType.LoyaltyClub
        default:
            return "banner"
    }
}

const CACHE_KEY = "campaigns"

export const all = (campaignType: CampaignTypeEnum) => {
    return async (dispatch: Dispatch) => {
        dispatch(campaignsActions.loading())

        try {
            const storeId = await patchStore()
            const tags = getTags(campaignType)
            const predicate = { storeId, tags }
            const cached = await Cacher.get(CACHE_KEY, predicate)
            if (cached) {
                // No need to set currentlyLoadingArgs when using cached response
                dispatch(campaignsActions.fromCache(campaignType, cached))
            } else {
                const rawData = await performRESTRequest({
                    path: `/api/products/{chainid}/${storeId}/campaigns`,
                    qs: {
                        tags,
                        full_response: true,
                        page_size: 20,
                    },
                    method: "GET",
                })
                const data = (rawData.hits.hits || [])
                    .map((i: any) => {
                        return ({
                            ...i._source,
                            isMenyMerPri: i._source?.tags?.includes(CampaignType.LoyaltyClubPri),
                            fromDate: i._source?.from,
                            toDate: i._source?.to,
                        })
                    })

                const sortedList = orderBy(data, ["isMenyMerPri", "fromDate", "toDate"], ["desc", "desc", "asc"])

                dispatch(campaignsActions.success(campaignType, sortedList))

                // save response data to cache
                Cacher.cache(CACHE_KEY, predicate, sortedList)
            }
        } catch (e) {
            dispatch(campaignsActions.error(e))
            Logger.error("actions/offerCampaigns/all()", e)
        } finally {
            dispatch(campaignsActions.loaded())
        }
    }
}
