import "./style/julelotteri.scss"

import React, { useEffect, useState, useRef } from "react"
import { useSelector } from "react-redux"
import PlayableLottery from "./PlayableLottery"
import { LoginButton, TextLink } from "@ng-mw/shared-react-components/shared"
import ConsentsActivator from "@ng-mw/shared-meny-components/src/modules/consents/ConsentsActivator"
import { MenyConsents } from "@ng-mw/shared-meny-components/src/modules/consents/loaders"
// import ChristmasConsent from "@ng-mw/shared-meny-components/src/modules/consents/ChristmasConsent"
import { consentsConstants, consentsHelpers } from "@ng-mw/shared-react-components/consents"
import { isChristmasCampaignTime, isAdvent } from "@ng-mw/shared-meny-components/src/helpers/datePeriods"
import Choppy from "./Choppy"
import { Consent, RootState } from "@app/types"

import { LifecycleEmitter, LifecycleEvent } from "@ng-mw/shared-react-components"
import Icon from "@ng-mw/reol/components/Icon"
import Button from "@ng-mw/reol/components/Button"
import arrowExternalIcon from "@ng-mw/reol/icons/arrowExternal"
import snowcrystalIcon from "@resources/icons/snowcrystal"
import wavyLineIcon from "@resources/icons/wavyLine"

const campaignVisible = isChristmasCampaignTime(new Date())
const lotteryStarted = isAdvent(new Date())

const emailWhitelist = [
    consentsConstants.ConsentType.NewsEmail,
]
const pushWhitelist = [
    consentsConstants.ConsentType.MobilePush,
]
// const xmasWhiteList = [
//     {
//         type: "JUL",
//         channel: "Push",
//     },
// ]

const lotteryBaseUrl = "https://meny-norway.campaign.playable.com/julelotteriet-2023-app/"

const Julelotteri = () => {

    const userLoginLevel = useSelector((state: RootState) => state.user.loginLevel)
    const { isOrganization } = useSelector((state: RootState) => state.user.data.household)
    const isLoggedIn = userLoginLevel > 0
    const chainId = useSelector((state: RootState) => state.user.data.chainId)
    const consents = useSelector((state: RootState) => state.consents)
    const consentsInitialized = useSelector((state: RootState) => state.consents.consentsInitialized)
    const [missingEmail, setMissingEmail] = useState(false)
    const [missingPush, setMissingPush] = useState(false)
    // const [notSignedUp, setNotSignedUp] = useState(false) // commented out for now since we don't have xmas consent this year

    const isFirstChange = useRef(true)
    // const { day } = getDateParts(new Date())

    // fetch the consents just to hide some stuff when already signed up
    useEffect(() => {
        if (consentsInitialized) {
            if (isFirstChange.current) {
                const emailConsent: Consent[] = consentsHelpers.filterConsentsByWhitelist(
                    consentsHelpers.filterConsentsByChainId(consents.data, chainId),
                    emailWhitelist,
                )
                const pushConsent: Consent[] = consentsHelpers.filterConsentsByWhitelist(
                    consentsHelpers.filterConsentsByChainId(consents.data, chainId),
                    pushWhitelist,
                )

                if (!emailConsent.some(c => c.status === "true")) {
                    setMissingEmail(true)
                } else if (!pushConsent.some(c => c.status === "true")) {
                    setMissingPush(true)
                }
                isFirstChange.current
            }
            // commented out for now since we don't have xmas consent this year:
            // const xmasConsent: Consent[] = consentsHelpers.filterConsentsByWhitelist(
            //     consentsHelpers.filterConsentsByChainId(consents.data, chainId),
            //     xmasWhiteList,
            // )
            // setNotSignedUp(!xmasConsent.some(c => c.status === "true"))
        }

    }, [
        consents.data,
        consentsInitialized,
        chainId,
    ])

    if (!campaignVisible || isOrganization) {
        return null
    }
    return (
        <>
            <Choppy />
            <div className="christmas-campaign">
                <div className="christmas-campaign__content">
                    <h4 className="christmas-campaign__title">
                        { lotteryStarted ? "Spill og vinn hver dag med " : "Snart er det tid for" }

                        <img
                            src="https://meny.no/Static/images/julelotteri-logo-uten-shadow-white.svg"
                            className="christmas-campaign__logo"
                            alt="Julelotteriet"
                            width="251"
                            height="69"
                        />
                    </h4>

                    {! lotteryStarted ? (
                        <>
                            <p className="christmas-campaign__text">
                                Snart er det tid for årets koseligste julekalender med tusenvis av flotte premier!
                            </p>
                            <Button
                                className="christmas-campaign__prize-link-button"
                                text="Se hva du kan vinne"
                                elementType="span"
                                tabIndex={0}
                                role="link"
                                iconRight={arrowExternalIcon}
                                onClick={() => LifecycleEmitter.broadcast(LifecycleEvent.ExternalLink, { url: "https://meny.no/tema/jul/julelotteriet/premier/" })}
                                onKeyDown={ (e: { keyCode: number }) => e.keyCode === 13 && LifecycleEmitter.broadcast(LifecycleEvent.ExternalLink, { url: "https://meny.no/tema/jul/julelotteriet/premier/" })}

                            />
                        </>
                    ) : !isLoggedIn ? (
                        <div>
                            <LoginButton caption="Logg inn for å spille" />
                        </div>
                    ) : (
                        <>
                            <PlayableLottery
                                url={lotteryBaseUrl}
                                frameTitle="Julelotteriet"
                                value="Test_app_julelotteriet"
                                type="coupon"
                            />

                            {missingEmail || missingPush ? (
                                <Icon
                                    icon={wavyLineIcon}
                                    className="christmas-campaign__wave"
                                    size={{width: "100%", height: "48px"}}
                                />
                            ) : null}

                            { missingEmail ? (
                                <MenyConsents
                                    title="Bli varslet om kuponger og andre fordeler"
                                />
                            ) : missingPush ? (
                                <ConsentsActivator
                                    title="Bli varslet om kuponger og andre fordeler"
                                    consentsWhitelist={[consentsConstants.ConsentType.MobilePush]}
                                />
                            // commented out for now since we don't have xmas consent this year:
                            // ) : day < 24 ? (
                            //     <ChristmasConsent
                            //         mobile
                            //         title="Vil du ha påminnelse om neste spill?"
                            //         pageId="christmas1"
                            //     />
                            ) : null}

                        </>
                    )}

                    <Icon
                        icon={wavyLineIcon}
                        className="christmas-campaign__wave"
                        size={{width: "100%", height: "48px"}}
                    />
                    <TextLink
                        className="christmas-campaign__ruleslink"
                        isBlock
                        isExternal
                        href="https://meny.no/tema/jul/julelotteriet/Konkurranseregler-julelotteriet/"
                        text="Les konkurransereglene på MENY.no"
                    />
                </div>

                <Icon
                    icon={snowcrystalIcon}
                    className="christmas-campaign__crystal1"
                    noSize
                />
                <Icon
                    icon={snowcrystalIcon}
                    className="christmas-campaign__crystal2"
                    noSize
                />
                <Icon
                    icon={snowcrystalIcon}
                    className="christmas-campaign__crystal3"
                    noSize
                />
                <Icon
                    icon={snowcrystalIcon}
                    className="christmas-campaign__crystal4"
                    noSize
                />
            </div>
        </>
    )
}

export default Julelotteri
