import { App } from "@ng-mw/shared-react-components"

export default {
    ...App.LocalStorage,
    DietSugarProperty: "TRUMF_DIET_SUGAR_PROPERTY",
    MockAccessToken: "MENY_MOCK_ACCESS_TOKEN",
    UserAskedToRateApp: "MENY_USER_ASKED_TO_RATE_APP",
    UserInteractedWithFeedback: "CLOSED_FEEDBACK_", // + feedbackId
    UserSeenDietAddedSugarInfo: "TRUMF_HAS_SEEN_DIET_ADDED_SUGAR_INFO",
    UserSeenDietOnboardingPopup: "TRUMF_HAS_SEEN_DIET_ONBOARDING_POPUP", // Deprecated
    UserSeenInsightOnboardingPopup: "TRUMF_HAS_SEEN_INSIGHT_ONBOARDING_POPUP",
    UserSeenAppNewsPopup: "MENY_HAS_SEEN_NEWS_POPUP",
    UserSeenAppNewsTrumfBonusPopup: "MENY_HAS_SEEN_NEWS_TRUMF_BONUS_POPUP",
    UserSeenLoyaltyClubWelcomePopup: "MENY_HAS_SEEN_WELCOME_POPUP",
    UserSeenLoyaltyClubWelcomeBox: "MENY_HAS_SEEN_WELCOME_BOX",
    UserSeenNewOnboarding: "USER_HAS_SEEN_NEW_ONBOARDING",
    UserSeenPushPopup: "USER_HAS_SEEN_PUSH_POPUP",
    UserSeenPersonalCampaign: "USER_HAS_SEEN_PERSONAL_CAMPAIGN",
    MobilePushStatus: "MOBILE_PUSH_STATUS",
}
