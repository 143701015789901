import strawberryIcon from "@resources/icons/strawberry"
import breadrollIcon from "@resources/icons/breadroll"
import fishIcon from "@resources/icons/fish"
import menyMerCoupons from "@resources/icons/menyMerCoupons"
import coins2 from "@resources/icons/coins2"
import arrow8 from "@resources/icons/arrow8"
import radish1 from "@resources/icons/radish1"
import sausage from "@resources/icons/sausage"
import precent2 from "@resources/icons/precent2"
import tenPercent from "@resources/icons/tenPercent"
import webshop from "@resources/icons/webshop"

const getIconFromStringName = (iconName: string) => {
    switch (iconName) {
        case "strawberryIcon":
            return strawberryIcon
        case "fishIcon":
            return fishIcon
        case "coinIcon":
            return coins2
        case "coiledArrowIcon":
            return arrow8
        case "raddishIcon":
            return radish1
        case "breadrollIcon":
            return breadrollIcon
        case "sausageIcon":
            return sausage
        case "mmCoponIcon":
            return menyMerCoupons
        case "mmPercentIcon":
        case "Bonus":
            return precent2
        case "mmTenPercent":
        case "10-bonus":
            return tenPercent
        case "webshop":
        case "Netthandel":
        case "Stor":
            return webshop
        default:
            return null
    }
}

export default getIconFromStringName
