import { GlobalSettings } from "@ng-mw/shared-meny-components"

/**
 * Extends GlobalSettings from @ng-mw/shared-meny-components with additional app-specific settings.
 */
type GlobalSettings = typeof GlobalSettings;
interface AppGlobalSettings extends GlobalSettings {
    isProduction: boolean
    aggressivePreload: boolean
    androidAppUrl: string
    customerServicePhone: string
    chainsiteUrl: string
    enableTrumfId: boolean
    environment: string
    iOSAppUrl: string
    isDebug: () => boolean
    serviceWorkerUrl: string
    tabletUsesGridProducts: boolean
    customerSupportUrl?: string
    requiredAndroidVersion: string
    requiredIOSVersion: string
    requiredAndroidAppVersion: string
    requiredIOSAppVersion: string
    recommendedAndroidAppVersion: string
    recommendedIOSAppVersion: string
    loyaltyClubAgreementId: string
    loyaltyClubStartCampaignId: string
    loyaltyClubWeeklyPromotionTag: string
    addToCartEventOnNumOfItemsInCart: number
}

const enableServiceWorker = true

export default {
    ...GlobalSettings,
    aggressivePreload: false,
    androidAppUrl: "https://play.google.com/store/apps/details?id=no.norgesgruppen.apps.meny",
    customerServicePhone: "+4722563500",
    enableTrumfId: true, // Now enabled in all platforms
    iOSAppUrl: "https://apps.apple.com/no/app/meny-handleapp/id662833115",
    isDebug: () => process.env.NODE_ENV !== "production" || window.location.search.indexOf("debug") !== -1,
    tabletUsesGridProducts: true,
    serviceWorkerUrl: enableServiceWorker && process.env.NODE_ENV === "production" ? "serviceWorker.js" : null,

    // Triggers forced OS update screens
    requiredAndroidVersion: "28", // required for App 2.0 support
    requiredIOSVersion: "13.6", // required for Trumf-id support

    // Triggers forced app update screens
    requiredAndroidAppVersion: "45",
    requiredIOSAppVersion: "45",

    // Triggers optional upgrade screen (must be higher than required versions to have any effect)
    recommendedAndroidAppVersion: "39",
    recommendedIOSAppVersion: "39",
} as AppGlobalSettings
