import "./style/external-lottery.scss"

import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import classNames from "classnames"
import { DebugLogger, LocalStorage, validatorHelper, LifecycleEmitter, Cacher } from "@ng-mw/shared-react-components"
import { useHistory } from "react-router-dom"
import PathNames from "@app/const/PathNames"
import MenyLifecycleEvent from "@app/events/MenyLifecycleEvent"

// Icons
import closeIcon from "@ng-mw/reol/icons/close"

// Components
import { TextLink } from "@ng-mw/shared-react-components/shared"
import Spinner from "@ng-mw/reol/components/Spinner"
import Button from "@ng-mw/reol/components/Button"
import Icon from "@ng-mw/reol/components/Icon"
import { RootState } from "@app/types"
import { hideGamePopup } from "@app/store/app/appActions"

// ==== NOTE: =======================================================================================
// IMPORTANT!
// In orer for this to work properly, the Playable game should be set up WITH NO HTTP REDIRECTS!!!
//
// There is probably multiple places where a redirect can be configured...
// Last time it didn't work because of redirects, I found the redirects configured near the bottom
// of the "Advanced" tab for each win/loose-page within in the campaign builder.
//
// See playable_custom_scripts.js and playable_custom_style.css (both in the /scripts folder of
// this repo) for detals about how to set up callbacks that handleResponse below can respond to.
// ==================================================================================================

const DEBUG_GAMESTATES = false

const stringToLittleEndianHex = (rawInput: string) => {
    const input = `${rawInput}` // convert to string in case it's a number
    const output: string[] = []
    for (let i = 0; i < input.length; i++) {
        const hexnumber = input.charCodeAt(i).toString(16).padStart(4, "0")
        const littleEndian = hexnumber.substring(2, 4) + hexnumber.substring(0, 2)
        output.push(littleEndian)
    }
    return output.join("")
}

interface MessageData {
    action: string
    data?: any
}

interface PlayableLotteryProps {
    url: string
    frameTitle?: string
    value?: string
    type?: string
    backgroundColor?: string
    tipLinkText?: string
}

const PlayableLottery = ({
    url,
    frameTitle = "Spill",
    value = "Test_app",
    type = "coupon",
    backgroundColor = "#C80F2E",
    tipLinkText = "Se dagens tips",
}: PlayableLotteryProps) => {
    const dispatch = useDispatch<any>()
    const memberId = useSelector((state: RootState) => state.user.data.currentMember?.memberId)

    const frameSrc = `${url}?send-game-event=1&iframe=dontbreakout&memberId=${stringToLittleEndianHex(memberId)}&type=${type}&value=${value}`

    const [popupOpen, setPopupOpen] = useState(true)
    const [error, setError] = useState("")
    const [resultData, setResultData] = useState<any>(null)
    const [gamestate, setGamestate] = useState(null)
    const history = useHistory()

    // const startGame = () => {
    //     setPopupOpen(true)
    //     setResultData(null)
    //     setError("")
    // }
    const endGame = (newResultData?: any) => {
        setPopupOpen(false)
        dispatch(hideGamePopup())
        if (newResultData?.winner) {
            LocalStorage.set("POTENTIALLY_WON_COUPON", new Date())

            setResultData({
                ...newResultData,
                title: "Du vant!",
            })

            Cacher.empty("coupons")

            history.push({ pathname: PathNames.Vouchers, state: {} })
        } else if (newResultData?.winner === false) {
            setResultData({
                ...newResultData,
                title: "Bedre lykke neste gang!",
            })
            const tipsPageUrl = validatorHelper.validateUrl(newResultData?.targetUrl)

            if (tipsPageUrl.startsWith("http")) {
                DebugLogger.log("Validated url:", tipsPageUrl)
                LifecycleEmitter.broadcast(MenyLifecycleEvent.ExternalLink, { url: tipsPageUrl })
            } else {
                DebugLogger.log("Invalid url:", tipsPageUrl, " going to GenericOffers instead")
                history.push({ pathname: PathNames.GenericOffers, state: {} })

            }
        } else {
            if (newResultData) {
                setResultData(newResultData)
            }
        }
    }
    const justClose = () => {
        endGame()
    }

    const handleResponse = (data: MessageData) => {
        switch (data.action) {
            // custom actions:

            // Event to just close the popup
            case "custom-dismiss":
                endGame({
                    ...resultData,
                    ...data.data,
                })
                break
            // Two custom cevents to test the error messages
            case "fake-error":
                setError("fake-error-message")
                endGame()
                break
            case "fake-no-more-spins":
                endGame({
                    ...resultData,
                    ...data.data,
                    title: "Du har allerede spilt i dag",
                })
                break

            // the following are all real actions that we don't need to handle
            // only uncomment the debug logging locally for debugging, some of them are very noisy
            case "request-url":
                // DebugLogger.log("Lottery action: request url. data: ", data.data)
                break
            case "campaign-ready":
                // DebugLogger.log("Lottery action: loaded data:", data)
                break
            case "resize":
                // DebugLogger.log("Lottery action: resized to ", data.data)
                break
            case "scroll-to-top":
                // DebugLogger.log("Lottery action: scroll to top")
                break

            // Handle win/loose callbacks
            case "game-state":
                setGamestate(data.data)
                if (data.data.winner) {
                    // {
                    //     "data": {
                    //         "winner":true,
                    //         "metricData": {"wof_field":1},
                    //         "registrationId":175703996,
                    //         "bulkPrize":{"code":"iDwEFP"}
                    //     }
                    // }

                    DebugLogger.log("Lottery: Winner was true! ", data.data)
                } else {

                    // {
                    //     "action": "game-state"
                    //     "data": {
                    //         "winner":false,
                    //         "metricData": { "wof_field":2 },
                    //         "registrationId":175729676
                    //     },
                    // }

                    DebugLogger.log("Lottery: Winner was falsy ", data.data)
                }
                break
            default:
                DebugLogger.log("Lottery: Got unexpected action: ", data)
        }
    }

    useEffect(() => {
        const messageHandler = (message: MessageEvent) => {
            if (message.source === window) {
                DebugLogger.log("Ignoring message from same window")
                return
            }

            try {
                const data = JSON.parse(message.data)
                if (data.type === "leadfamly-campaign" && data.action) {
                    handleResponse(data)
                } else {
                    DebugLogger.log("Got unexpected message (NOT leadfamly-campaign): ", { data })
                }
            } catch (error) {
                // Ignore parsing errors (probably from payment terminal)
            }
        }

        window.addEventListener("message", messageHandler)
        // const unloadPreventer = e => {
        //     setPopupOpen(false)
        //     // Cancel the event
        //     e.preventDefault() // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        //     // Chrome requires returnValue to be set
        //     e.returnValue = ""
        // }
        // window.addEventListener("beforeunload", unloadPreventer)

        // clean up
        return () => {
            window.removeEventListener("message", messageHandler)
            endGame()
            // window.removeEventListener("beforeunload", unloadPreventer)
        }
        // Ignore exhaustive deps. The dep "handleResponse" will be moved into the useEffect block once debugging is over
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // empty array => run only once

    const safeArea = useSelector((state: RootState) => state.native.safeArea)

    return (
        <div
            className={classNames("lottery", {
                "lottery--is-open": popupOpen,
            })}
        >
            {
                // <LazyRender
                //     isVisible={popupOpen}
                //     className="lottery__framewrap"
                //     visibleClassName="lottery__framewrap--is-open"
                //     style={{ paddingTop: safeArea.top, paddingBottom: safeArea.bottom }}
                // >
            }
            <div
                className={classNames("lottery__framewrap", {
                    "lottery__framewrap--is-open": popupOpen,
                })}
                style={{
                    backgroundColor,
                }}
            >
                <div className="lottery__inner">
                    <button
                        className="lottery__extra-close"
                        onClick={justClose}
                        title="Lukk"
                        style={{
                            marginTop: safeArea.top,
                        }}
                    >
                        <Icon
                            icon={closeIcon}
                            size={{ width: "12px", height: "12px" }}
                        />
                    </button>
                    <Spinner
                        isInverted
                        size="large"
                    />
                    {DEBUG_GAMESTATES ? (
                        <div className="lottery__debug_buttons">
                            <Button
                                text="win"
                                onClick={() => {
                                    handleResponse({ action: "custom-dismiss", data: { winner: true } })
                                }}
                            />
                            <Button
                                text="lose"
                                onClick={() => {
                                    handleResponse(
                                        {
                                            action: "custom-dismiss",
                                            data: {
                                                winner: false,
                                                linkText: undefined,
                                                // targetUrl: "https://meny.no/tema/jul/julekaker/Julens-7-slag/",
                                            },
                                        },
                                    )
                                }
                                }
                            />
                            {
                                // <Button
                                //     text="no spins"
                                //     onClick={() => { handleResponse({ action: "fake-no-more-spins" })}}
                                // />
                            }
                            <Button
                                text="error"
                                onClick={() => { handleResponse({ action: "fake-error" }) }}
                            />
                        </div>
                    ) : null}
                    <iframe
                        id="lottery__iframe"
                        title={frameTitle}
                        src={frameSrc}
                        sandbox="allow-forms allow-scripts allow-same-origin"
                    />
                </div>
            </div>
            {error ? (
                <div>
                    <h2>Noe gikk galt</h2>
                    <h3>Prøv igjen litt senere...</h3>
                    <p>Feilmelding: <i>{error}</i></p>
                </div>
            ) : null}
            <div>
                {resultData?.title ? (
                    <div>
                        <h2>{resultData.title}</h2>
                        {resultData.targetUrl ? (
                            <TextLink
                                className="lottery__tipslink"
                                isBlock
                                isExternal
                                href={resultData.targetUrl}
                                text={resultData.linkText ?? tipLinkText}
                            />
                        ) : null}
                    </div>
                ) : null}
            </div>
            {DEBUG_GAMESTATES ? (
                <code>
                    GAMESTATE: {JSON.stringify(gamestate, null, 2)}
                </code>
            ) : null}
        </div>
    )
}

export default PlayableLottery
